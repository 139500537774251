import { Injectable } from '@angular/core';
import { ConfirmationModalComponent, ProjectDisplayComponent } from 'src/app/_shared/components/modals';
import { ConfirmationOptions, ProjectDisplayOptions } from 'src/app/_shared/models';

import { ModalService } from './modal.service';

@Injectable({
  providedIn: 'root'
})
export class ComponentModalService {
  constructor(private modalService: ModalService) { }

  showConfirmationModal(options: ConfirmationOptions = {}): Promise<boolean> {
    return this.modalService.open<ConfirmationOptions, boolean>(ConfirmationModalComponent,
      {
        keyboard: false,
        backdrop: 'static',
        windowClass: 'confirmation-modal__content'
      },
      options);
  }

  showProjectDisplayModal(options: ProjectDisplayOptions): Promise<void> {
    return this.modalService.open<ProjectDisplayOptions, void>(ProjectDisplayComponent,
      {
        keyboard: false,
        backdrop: true,
        windowClass: 'project-display-modal__content',
        size: 'lg'
      },
      options);
  }
}
