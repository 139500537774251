<div class="custom-pagination">
    <ng-content></ng-content>
    <button class="btn-pagination" (click)="jumpToFirst()" [disabled]="checkFirstPage()"
        [ngStyle]="{ 'background-color': colorTheme, 'color': fontColor }">
        <i class='bx bxs-chevrons-left'></i>
    </button>
    <button class="btn-pagination" (click)="previous()" [disabled]="checkFirstPage()"
        [ngStyle]="{ 'background-color': colorTheme, 'color': fontColor }">
        <i class='bx bxs-chevron-left'></i>
    </button>
    <span class="pagination__counter" [ngStyle]="{ 'background-color': colorTheme, 'color': fontColor }">
        {{ index + 1 }} of {{ itemLength }}
    </span>
    <button class="btn-pagination" (click)="next()" [disabled]="checkLastPage()"
        [ngStyle]="{ 'background-color': colorTheme, 'color': fontColor }">
        <i class='bx bxs-chevron-right'></i>
    </button>
    <button class="btn-pagination" (click)="jumpToLast()" [disabled]="checkLastPage()"
        [ngStyle]="{ 'background-color': colorTheme, 'color': fontColor }">
        <i class='bx bxs-chevrons-right'></i></button>
</div>
