import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { QueryResponse, SearchParam } from 'src/app/_shared/models';
import { AngularFirestore, AngularFirestoreCollection,
  CollectionReference, DocumentData,
  Query, QuerySnapshot
} from '@angular/fire/firestore';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class BaseFirestoreService {

  constructor(
    private firestore: AngularFirestore
  ) { }

  /**
   * Retrieves a single record base on single column
   *
   * @param table - The name of the Firestore collection to retrieve.
   * @param column - The name of the column to filter the collection by.
   * @param value - The value to search for in the specified column.
   * @returns A Firestore query that retrieves documents matching the specified search parameters.
   */
  getDataByColumn<T>(table: string, column: string, value: string): Observable<QueryResponse<T>> {
    const collectionRef: AngularFirestoreCollection<T> = this.firestore.collection<T>(table, ref => ref.where(column, '==', value));
    const querySnapshot: Observable<QuerySnapshot<T>> = collectionRef.get();

    const result: Observable<QueryResponse<T>> = querySnapshot
      .pipe(
        map(snapshot => this.createQueryResponse<T>(snapshot?.empty, snapshot?.docs[0]?.id, snapshot?.docs[0]?.data()))
      );

    return result;
  }

  /**
   * Retrieves a single record base on multiple columns
   *
   * @param table - The name of the Firestore collection to retrieve.
   * @param searchParams - An array of search parameters to filter the collection by.
   * @returns A Firestore query that retrieves documents matching the specified search parameters.
   */
  getDataByColumns<T>(table: string, searchParams: SearchParam[]): Observable<QueryResponse<T>> {
    const collectionRef: AngularFirestoreCollection<T> = this.firestore.collection<T>(table,
      ref => this.queryMultiColumns(ref, searchParams));
    const querySnapshot: Observable<QuerySnapshot<T>> = collectionRef.get();

    const result: Observable<QueryResponse<T>> = querySnapshot
      .pipe(
        map(snapshot => this.createQueryResponse<T>(snapshot?.empty, snapshot?.docs[0]?.id, snapshot?.docs[0]?.data()))
      );

    return result;
  }

  /**
   * Retrieves multiple records base on single column
   *
   * @param table - The name of the Firestore collection to retrieve.
   * @param column - The name of the column to filter the collection by.
   * @param value - The value to search for in the specified column.
   * @returns A Firestore query that retrieves documents matching the specified search parameters.
   */
  getListByColumn<T>(table: string, column: string, value: string): Observable<QueryResponse<T[]>> {
    const collectionRef: AngularFirestoreCollection<T> = this.firestore.collection<T>(table, ref => ref.where(column, '==', value));
    const querySnapshot: Observable<QuerySnapshot<T>> = collectionRef.get();
    const result: Observable<QueryResponse<T[]>> = querySnapshot
      .pipe(
        map(snapshot => this.createQueryResponse<T[]>(snapshot?.empty, snapshot?.docs[0]?.id, snapshot?.docs?.map(d => d.data())))
      );

    return result;
  }

  /**
   * Retrieves multiple records base on multiple columns
   *
   * @param table - The name of the Firestore collection to retrieve.
   * @param searchParams - An array of search parameters to filter the collection by.
   * @returns A Firestore query that retrieves documents matching the specified search parameters.
   */
  getListByColumns<T>(table: string, searchParams: SearchParam[]): Observable<QueryResponse<T[]>> {
    const collectionRef: AngularFirestoreCollection<T> = this.firestore.collection<T>(table,
      ref => this.queryMultiColumns(ref, searchParams));
    const querySnapshot: Observable<QuerySnapshot<T>> = collectionRef.get();
    const result: Observable<QueryResponse<T[]>> = querySnapshot
      .pipe(
        map(snapshot => this.createQueryResponse<T[]>(snapshot?.empty, snapshot?.docs[0]?.id, snapshot?.docs?.map(d => d.data())))
      );

    return result;
  }

  private createQueryResponse<T>(isEmpty: boolean, id: string, data: T) {
    const result: QueryResponse<T> = {
      isEmpty,
      id,
      data
    };
    return result;
  }

  private queryMultiColumns(ref: CollectionReference<DocumentData>, searchParams: SearchParam[]): Query {
    let query: Query = ref;
      searchParams.forEach(param => {
        query = query.where(param.column, '==', param.value);
    });
    return query;
  }
}
