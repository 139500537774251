import { AfterViewInit, Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { SideNavOffset } from '../_shared/models';
import { ObjectUtils } from '../_shared/utils';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ScrollService } from '../_shared/services';
import { SideNavTabEnum } from '../_shared/enums';

@Component({
  selector: 'app-resume',
  templateUrl: './resume.component.html',
  styleUrls: ['./resume.component.scss']
})
export class ResumeComponent implements OnInit, AfterViewInit {
  @ViewChild('resume') resumeSection: ElementRef<HTMLElement>;

  constructor(
    private scrollService: ScrollService,
    private http: HttpClient
    ) { }

  @HostListener('window:resize', ['$event'])
  onWindowResize(): void {
    this.sendOffsetData();
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.sendOffsetData();
  }

  viewResume(): void {
    const route = '/resume/view';
    window.open(route, '_blank');
  }

  downloadResume(): void {
    const filename = 'Kent Gentilezo - Software Engineer Resume.pdf';
    const path = environment.resumePath;

    this.http.get(path, { responseType: 'blob' }).subscribe((blob: Blob) => {
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = filename;
      link.click();
    });
  }

  private sendOffsetData(): void {
    if (ObjectUtils.isEmpty(this.resumeSection)) {
      return;
    }

    const offsetTop: number = this.resumeSection.nativeElement.offsetTop;
    const offsetHeight: number = this.resumeSection.nativeElement.offsetHeight;
    const offset: SideNavOffset = {
      top: offsetTop,
      height: offsetHeight
    };

    this.scrollService.setOffset(SideNavTabEnum.resume, offset);
  }
}
