import { Component, OnInit } from '@angular/core';
import { NavEventsService } from '../_shared/services';

@Component({
  selector: 'app-page-footer',
  templateUrl: './page-footer.component.html',
  styleUrls: ['./page-footer.component.scss']
})
export class PageFooterComponent implements OnInit {

  constructor(private navEventsService: NavEventsService) { }

  ngOnInit(): void {
  }

  clickSection(): void {
    if (this.checkMobileNavActive()) {
      this.navEventsService.toggleSideNav();
    }
  }

  private checkMobileNavActive(): boolean {
    return document.body.classList.contains('mobile-nav-active');
  }
}
