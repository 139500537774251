import { AfterViewInit, Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { SideNavOffset } from '../_shared/models';
import { ObjectUtils } from '../_shared/utils';
import { ScrollService } from '../_shared/services';
import { SideNavTabEnum } from '../_shared/enums';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit, AfterViewInit {
  @ViewChild('contact') contactSection: ElementRef<HTMLElement>;
  linkedInLink: string = 'https://www.linkedin.com/in/kent-gentilezo/';
  emailAddress: string = 'kgentilezo@outlook.com';

  constructor(private scrollService: ScrollService) { }

  @HostListener('window:resize', ['$event'])
  onWindowResize(): void {
    this.sendOffsetData();
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.sendOffsetData();
  }

  openEmail(): void {
    window.open(`mailto:${this.emailAddress}`, '_self');
  }

  openLinkedIn(): void {
    window.open(this.linkedInLink, '_blank');
  }

  private sendOffsetData(): void {
    if (ObjectUtils.isEmpty(this.contactSection)) {
      return;
    }

    const offsetTop: number = this.contactSection.nativeElement.offsetTop;
    const offsetHeight: number = this.contactSection.nativeElement.offsetHeight;

    const offset: SideNavOffset = {
      top: offsetTop,
      height: offsetHeight
    };

    this.scrollService.setOffset(SideNavTabEnum.contact, offset);
  }
}
