export interface NavItem {
  icon: string;
  label?: string;
  route?: string;
}


export interface SideNavOffset {
  top?: number;
  height?: number;
}
