export interface SearchParam {
  column: string;
  value: string;
}


export interface QueryResponse<T> {
  isEmpty: boolean;
  id: string;
  data: T;
}

