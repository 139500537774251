export const DEMO_ACCOUNTS = {
  user: {
    username: 'user_demo',
    password: 'userdemo123'
  },
  admin: {
    username: 'admin_demo',
    password: 'admindemo123'
  }
};
