<ngb-alert *ngFor="let message of messages" class="{{cssClass(message)}}" (close)="removeAlert(message)"
  [dismissible]="message.dismissible">

  <div *ngFor="let ref of customHtmlMessageRef">
    <ng-container *ngTemplateOutlet="ref.templateRef"></ng-container>
  </div>

  <div *ngIf="!customHtmlMessageRef?.length && message?.message">
    <p class="alert__text">
      {{message.message}}
    </p>
  </div>

</ngb-alert>