import { Component, HostListener, OnInit } from '@angular/core';
import { ScrollService } from '../_shared/services';
import { SideNavTabEnum } from '../_shared/enums';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  darkMode: boolean = false;
  showScrollTop: boolean = false;
  menuToggled: boolean = false;

  constructor(private scrollService: ScrollService) { }

  @HostListener('window:scroll', ['$event'])
  onScroll(): void{
    this.setScrollTopVisibility();
  }

  ngOnInit(): void {
  }

  toggleDarkMode(isToggled: boolean): void {
    this.darkMode = isToggled;
  }

  scrollToTop(): void {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  setMenuToggled(isToggled: boolean): void {
    this.menuToggled = isToggled;
  }

  private setScrollTopVisibility(): void {
    const currentTabOnScreen: string = this.scrollService.getTabSectionOnScreen();
    this.showScrollTop = currentTabOnScreen !== SideNavTabEnum.home;
  }
}
