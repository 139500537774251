import { isString } from 'lodash';

export class ObjectUtils {
    static hasData(data: unknown): boolean {

        if (Array.isArray(data)) {
            return data.length > 0;
        }

        if (isString(data))
        {
            return data.length > 0;
        }

        const isNotNullAndUndefined: boolean = data !== null && data !== undefined;
        return isNotNullAndUndefined;
    }

    static isEmpty(data: unknown): boolean {
        if (Array.isArray(data)) {
            return data.length === 0;
        }

        if (isString(data))
        {
            return data.length === 0;
        }

        const isNullOrUndefined: boolean = data === null || data === undefined;
        return isNullOrUndefined;
    }
  }
