<!-- ======= Resume Section ======= -->
<section id="resume" class="resume" #resume>
    <div class="container" data-aos="fade-up">
        <div class="section-title">
            <h2>Resume</h2>

        </div>

        <div class="row">
            <div class="col">
                <h3 class="resume-title">Professional Experience</h3>
                <div class="resume-item">
                    <h3>ASP.NET DEVELOPER</h3>
                    <h4>WellAtSea</h4>
                    <h5>August, 2023 - Current</h5>
                    <p><em>Makati, Philippines </em></p>
                    <ul>
                        <li>Implemented a report builder feature, reducing time and effort for program community coordinators by
                            35% compared to manual report creation, while also ensuring accurate data.</li>
                        <li>Developed a mail merge service using SMTP for bulk communications with seafarers, optimizing
                            communication processes by 40%.</li>
                        <li>Integrated API with Giftaway, facilitating real-time purchase of vouchers for seafarers, contributing to a
                            25% increase in efficiency.</li>
                    </ul>
                </div>
                <div class="resume-item">
                    <h3>Software Engineer</h3>
                    <h4>YouSource INC</h4>
                    <h5>February, 2021 - August 2023</h5>
                    <p><em>Makati, Philippines </em></p>
                    <ul>
                        <li>Worked collaboratively with cross-functional teams, including product management, design,
                            and QA, to
                            deliver high-quality software on time and within budget, resulting in a 20% increase in
                            customer satisfaction ratings</li>
                        <li>Designed and implemented a software solution that leveraged complex mathematical algorithms
                            to automate calculation-intensive tasks, achieving a 30% reduction in processing time and
                            significantly improving overall efficiency</li>
                        <li>Facilitated IIS deployment for multiple universities in South Africa, which led to a 50%
                            reduction
                            in deployment time and improved server stability for the client.</li>
                        <li>Setup and managed CI/CD pipelines for multiple web applications, utilizing Azure DevOps and
                            Azure resources such as Azure App Service, Azure Storage and SQL servers & databases.</li>
                        <li>Developed and maintained authentication and authorization mechanisms
                            using OIDC and Identity Server 4 for multiple projects.</li>
                        <li>Enhanced the application's features to effectively fix the bugs and 
                            optimize the overall performance, reliability, and efficiency.</li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <h3 class="resume-title">Education</h3>
                <div class="resume-item">
                    <h3>Bachelor of Science in Computer Science</h3>
                    <h5>2016 - 2020</h5>
                    <p><em>Polytechnic University of the Philippines </em></p>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col button-group-left">
                <a class="btn btn-resume" (click)="viewResume()"><i class='bx bx-clipboard'></i> View Full Resume</a>
            </div>
            <div class="col button-group-right">
                <a class="btn btn-resume" (click)="downloadResume()"><i class='bx bxs-download'></i> Download Full Resume</a>
            </div>
        </div>
    </div>
</section><!-- End Resume Section -->