import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbDropdownModule, NgbCollapseModule } from '@ng-bootstrap/ng-bootstrap';
import { MainComponent } from './main.component';
import { SharedModule } from '../_shared';
import { NavsModule } from '../navs/navs.module';
import { AboutComponent } from '../about/about.component';
import { ProfileComponent } from '../profile/profile.component';
import { SkillsComponent } from '../skills/skills.component';
import { ProjectsComponent } from '../projects/projects.component';
import { ResumeComponent } from '../resume/resume.component';
import { ContactComponent } from '../contact/contact.component';


@NgModule({
  declarations: [
    MainComponent,
    ProfileComponent,
    AboutComponent,
    SkillsComponent,
    ProjectsComponent,
    ResumeComponent,
    ContactComponent
  ],
  imports: [
    CommonModule,
    NavsModule,
    NgbDropdownModule,
    NgbCollapseModule,
    SharedModule
  ],
  exports: [MainComponent]
})
export class MainModule { }
