import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TopNavComponent } from './top-nav/top-nav.component';
import { RouterModule } from '@angular/router';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from '../_shared';
import { SideNavComponent } from './side-nav/side-nav.component';


@NgModule({
  declarations: [TopNavComponent, SideNavComponent],
  imports: [
    CommonModule,
    RouterModule,
    NgbDropdownModule,
    SharedModule
  ],
  exports: [TopNavComponent, SideNavComponent]
})
export class NavsModule { }
