import * as faker from 'faker';
import { Notification } from 'src/app/_shared/models';

export class MockDataHelper {
  static colorPalette: string[] = ['#00beff', '#6c757d', '#0c343d', '#7c98b6', '#196cc5'];

  constructor() { }

  public static createNotification(): Notification {
    const notification: Notification = {
      uid: faker.datatype.uuid(),
      content: faker.lorem.words(20),
      createdAt: faker.date.past(0.01).valueOf(),
      isRead: faker.datatype.boolean()
    };

    return notification;
  }
}
