import { Component, Input, OnInit } from '@angular/core';
import { fadeNoLeaveAnimation } from '../../animations/custom.animation';

@Component({
  selector: 'app-empty-state',
  templateUrl: './empty-state.component.html',
  styleUrls: ['./empty-state.component.scss'],
  animations: [fadeNoLeaveAnimation]
})
export class EmptyStateComponent implements OnInit {
  @Input() isVisible: boolean;
  @Input() placeholderText: string;

  constructor() { }

  ngOnInit(): void {
  }

}
