import { AfterViewInit, Component, EventEmitter, HostListener, OnInit, Output } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { SideNavTabEnum } from 'src/app/_shared/enums';
import { LocalStorageService, NavEventsService, ScrollService } from 'src/app/_shared/services';
import { COLOR_BLACK } from 'src/app/_shared/constants';

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss']
})
export class SideNavComponent implements OnInit, AfterViewInit {
  @Output() tabNavigated: EventEmitter<string> = new EventEmitter<string>();
  @Output() darkModeToggled: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() sideMenuToggled: EventEmitter<boolean> = new EventEmitter<boolean>();
  menuToggled: boolean = false;
  tabs: typeof SideNavTabEnum = SideNavTabEnum;
  currentTab: string = SideNavTabEnum.home;
  navColor: string = '#0563bb';
  colorBlack: string = COLOR_BLACK;
  darkMode: boolean = false;

  constructor(
    private router: Router,
    private location: Location,
    private localStorageService: LocalStorageService,
    private navEventsService: NavEventsService,
    private scrollService: ScrollService
    ){}

  @HostListener('window:scroll', ['$event'])
  onScroll(): void {
    this.checkScreenSection();
  }

  ngOnInit(): void {
    const loadedDarkMode: boolean = this.localStorageService.getDarkMode();
    this.darkMode = loadedDarkMode;
    this.toggleDarkMode(loadedDarkMode);

    this.setupEventsSubscription();
  }

  ngAfterViewInit(): void {
    this.checkRoute();
  }

  toggleMenu(): void {
    this.menuToggled = !this.menuToggled;
    this.sideMenuToggled.emit(this.menuToggled);

    if (this.menuToggled) {
      document.body.classList.add('mobile-nav-active');
    } else {
      document.body.classList.remove('mobile-nav-active');
    }
  }

  toggleDarkMode(isToggled: boolean): void {
    this.darkModeToggled.emit(isToggled);
    this.localStorageService.setDarkMode(isToggled);

    if (isToggled) {
      document.body.classList.add('dark');
    } else {
      document.body.classList.remove('dark');
    }

    if (this.menuToggled) {
      this.toggleMenu();
    }
  }

  navigateTab(tab: string): void {
    if (this.menuToggled) {
      this.toggleMenu();
    }

    this.currentTab = tab;
    this.tabNavigated.emit(tab);
    this.scrollToTab(tab);
  }


  scrollToTab(tab: string): void {
    this.scrollService.scrollToTab(tab);
  }

  isTabActive(tab: string): boolean {
    const isTabSelected: boolean = this.currentTab === tab;
    return isTabSelected;
  }

  private setupEventsSubscription(): void {
    this.navEventsService.sideNavToggled.subscribe(() => {
      this.toggleMenu();
    });
  }

  private checkScreenSection(): void {
    const tabOnScreen: string = this.scrollService.getTabSectionOnScreen();

    if (tabOnScreen !== this.currentTab) {
      this.currentTab = tabOnScreen;
      this.location.replaceState(`/${tabOnScreen.toLowerCase()}`);
    }
  }

  private checkRoute(): void {
    Object.values(SideNavTabEnum).forEach(tab => {
      if (this.router.url.includes(tab.toLowerCase())) {
        this.scrollToTab(tab);
      }
    });
  }
}
