import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ProjectDisplay, ProjectDisplayOptions } from 'src/app/_shared/models';
import { OtherUtils } from 'src/app/_shared/utils';

@Component({
  selector: 'app-project-display',
  templateUrl: './project-display.component.html',
  styleUrls: ['./project-display.component.scss']
})
export class ProjectDisplayComponent implements OnInit {
  @Input() data!: ProjectDisplayOptions;
  title: string = '';
  projectScreens: ProjectDisplay[] = [];
  imgSrc: string;
  colorTheme: string;
  fontColor: string;
  index: number = 0;
  imageLoading: boolean = true;

  constructor(
    public modalInstance: NgbActiveModal
    ) { }

  ngOnInit(): void {
    this.initializeData();
  }

  replayImage(): void {
    this.imgSrc = OtherUtils.resetImgSrc(this.projectScreens[this.index].imgPath);
  }

  close(): void {
    this.modalInstance.dismiss();
  }

  setCurrentIndex(index: number): void {
    this.index = index;
    this.imgSrc = this.projectScreens[this.index].imgPath;
    this.imageLoading = true;
  }

  imageLoaded(): void {
    this.imageLoading = false;
  }

  private initializeData(): void {
    this.title = this.data.projectName;
    this.projectScreens = this.data.projectScreens;
    this.colorTheme = this.data.colorTheme;
    this.fontColor = this.data.fontColor;
    this.imgSrc = this.projectScreens[this.index].imgPath;
  }
}
