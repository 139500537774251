import { ProjectDisplay } from '../models';

export const FUNDEASE_SCREENS: ProjectDisplay[] = [
    {
    title: 'Login',
    imgPath: 'https://firebasestorage.googleapis.com/v0/b/kent-gentilezo.appspot.com/'
    + 'o/FundEase%2Flogin.gif?alt=media&token=9f58a096-a4d9-4fb1-b8b7-0d855e6d8768',
    description: 'To log in, users are directed to a single login page '
    + 'managed by the identity server. This login page allows users to access '
    + 'multiple applications within the system without having to remember separate usernames and passwords. '
    + '<br><br>The identity server uses a secure protocol called OpenID Connect '
    + 'to verify the user\'s identity and ensure that only authorized users have access to the app'
    },
    {
        title: 'Sector Measure',
        imgPath: 'https://firebasestorage.googleapis.com/v0/b/kent-gentilezo.appspot.com/'
        + 'o/FundEase%2Fsector_measure.gif?alt=media&token=e2b66002-d5fb-4602-b71e-7e301fb9f6e5',
        description: 'Sector measures are a type of data measure that can be used in various universities. '
        + 'They are used to track and analyze data over time, typically on an annual basis. '
        + '<br><br>These measures can either be a value measure, where users enter values into a table, '
        + 'or a calculated measure, which can be a custom formula.'
    },
    {
        title: 'Institution Measure',
        imgPath: 'https://firebasestorage.googleapis.com/v0/b/kent-gentilezo.appspot.com/'
        + 'o/FundEase%2Finstitution_measure.gif?alt=media&token=0138cfa5-493a-4657-80a0-4e0a36b492ec',
        description: 'Institution measures are a type of data measure that can be used in a specific university. '
        + 'They help universities track and analyze data annually, so they can see how they\'re doing over time '
        + 'and identify areas where they need to improve. It can be customized to meet each university\'s specific needs. '
        + '<br><br>These measures can either be a value measure, where users enter values into a table, '
        + 'or a calculated measure, which can be a custom formula.'
    },
    {
        title: 'Calculated Measures',
        imgPath: 'https://firebasestorage.googleapis.com/v0/b/kent-gentilezo.appspot.com/'
        + 'o/FundEase%2Fcalculated_measures.gif?alt=media&token=6e9a9bf8-0703-4de9-8646-896a1b52c38c',
        description: 'Calculated measures are a type of data measure that are created using a formula, '
        + 'which can reference existing measures and use mathematical functions, operators, and logical expressions. '
        + '<br><br>The formula is entered into a calculation editor or blockly to be specific the tool utilized by the app, '
        + 'and the resulting calculated measure can be used in reports, dashboards, and visualizations.'
    },
    {
        title: 'Reports',
        imgPath: 'https://firebasestorage.googleapis.com/v0/b/kent-gentilezo.appspot.com/'
        + 'o/FundEase%2Freports.gif?alt=media&token=cc1c7edd-16fe-40bd-a0e1-317eee942fd6',
        description: 'A report is a way to summarize information from a large amount of data. '
        + 'The app allows users to create reports using pre-defined report structures. '
        + '<br><br>These structures are designed to display specific measures in a user-friendly way, '
        + 'such as through tables, and charts. These reports are designed to be easily configurable '
        + 'for display, allowing users to customize the format to suit their needs.'
    },
    {
        title: 'Report View',
        imgPath: 'https://firebasestorage.googleapis.com/v0/b/kent-gentilezo.appspot.com/'
        + 'o/FundEase%2Freport_view.gif?alt=media&token=3b576cc6-1730-4d5e-81a6-f35ff7769d77',
        description: 'Report view is a feature in which a built report is displayed in table form, '
        + 'typically presenting annual data. Additionally, charts may also be included in the display to provide '
        + 'a visual representation of the data. <br><br>This view is useful for quickly understanding trends and patterns within the data, '
        + 'and for communicating information to stakeholders in a clear and concise manner.'
    },
    {
        title: 'Report Run Results',
        imgPath: 'https://firebasestorage.googleapis.com/v0/b/kent-gentilezo.appspot.com/'
        + 'o/FundEase%2Freport_results.gif?alt=media&token=d6375c56-963c-4883-ac3f-0b66687704ca',
        description: 'Report run results refer to the snapshots of a report that are created '
        + 'when the report is run at a specific point in time. These results represent a version of the report '
        + 'at that particular moment, and can be used for comparison or historical analysis. '
        + '<br><br>By saving report results, users can track changes in data over time '
        + 'and better understand trends or patterns. Overall, report results are a valuable tool '
        + 'for gaining insight into data and making informed decisions.'
    },
    {
        title: 'Report Scenarios',
        imgPath: 'https://firebasestorage.googleapis.com/v0/b/kent-gentilezo.appspot.com/'
        + 'o/FundEase%2Freport_scenarios.gif?alt=media&token=3a18d308-81b2-49a9-8c24-830c24bb3db4',
        description: 'Report scenarios involve creating customized scenarios based on a report '
        + 'and applying variables to manipulate the data. This allows for the expansion of forecasting years '
        + 'by analyzing how different variables may impact the report\'s findings. '
        + '<br><br>In summary, report scenarios enable users to explore various possibilities and outcomes based on data analysis, '
        + 'which can inform decision-making and planning for the future.'
    }
];
