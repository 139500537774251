import { EventEmitter, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NavEventsService {
  public sideNavToggled: EventEmitter<void> = new EventEmitter<void>();

  constructor() { }

  toggleSideNav(): void {
    this.sideNavToggled.emit();
  }
}
