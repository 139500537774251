import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-custom-pagination',
  templateUrl: './custom-pagination.component.html',
  styleUrls: ['./custom-pagination.component.scss']
})
export class CustomPaginationComponent  {
  @Input() itemLength: number;
  @Input() colorTheme: string;
  @Input() fontColor: string;
  @Output() indexUpdated: EventEmitter<number> = new EventEmitter<number>();

  index: number = 0;

  constructor() { }

  next(): void {
    this.index += 1;
    this.indexUpdated.emit(this.index);
  }

  previous(): void {
    this.index -= 1;
    this.indexUpdated.emit(this.index);
  }

  jumpToFirst(): void {
    this.index = 0;
    this.indexUpdated.emit(this.index);
  }

  jumpToLast(): void {
    this.index = this.itemLength - 1;
    this.indexUpdated.emit(this.index);
  }

  checkFirstPage(): boolean {
    return this.index === 0;
  }

  checkLastPage(): boolean {
    return this.index === this.itemLength - 1;
  }
}
