export const COLOR_BRAND_PRIMARY = '#4daae0';
export const COLOR_PEACH_RED = '#FC596F';
export const COLOR_YELLOW_ORANGE = '#EBAD4B';
export const COLOR_LIGHT_BLUE = '#4BA1EB';
export const COLOR_LIGHT_GREEN = '#4DEA8F';
export const COLOR_PURPLE = '#D165D1';
export const COLOR_WHITE = '#FFFFFF';
export const COLOR_BLACK = '#000000';
export const COLOR_GRAY = '#666666';
export const LEARNER_HUB_THEME = '#705903bd';
export const LEARNER_HUB_THEME_LIGHT = '#ebba06bd';
export const FUNDEASE_THEME = '#7F0037';
export const FUNDEASE_THEME_LIGHT = '#ab034c';
