import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AngularFireAuthGuard } from '@angular/fire/auth-guard';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from '../services';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {
    constructor(private fireAuthGuard: AngularFireAuthGuard, private authService: AuthService, private router: Router) { }

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.checkUser(next, state);
    }

    private checkUser(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.fireAuthGuard.canActivate(next, state).pipe(map((fireAuthGuardResult) => {
            if (fireAuthGuardResult && this.authService.isUserTokenValid()) {
                return true;
            } else {
                this.router.navigate(['/login']);
            }
        }));
    }
}
