import { ProjectDisplay } from '../models';

export const LEARNER_HUB_SCREENS: ProjectDisplay[] = [
    {
    title: 'Login',
    imgPath: 'https://firebasestorage.googleapis.com/v0/b/kent-gentilezo.appspot.com/'
    + 'o/Learner_Hub%2Flogin.gif?alt=media&token=4e4e0009-4560-454c-966d-c0663551045c',
    description: 'To log in, users are directed to a single login page '
    + 'managed by the identity server. This login page allows users to access '
    + 'multiple applications within the system without having to remember separate usernames and passwords. '
    + '<br><br>The identity server uses a secure protocol called OpenID Connect '
    + 'to verify the user\'s identity and ensure that only authorized users have access to the app'
    },
    {
        title: 'Session',
        imgPath: 'https://firebasestorage.googleapis.com/v0/b/kent-gentilezo.appspot.com/'
        + 'o/Learner_Hub%2Fsession_form.gif?alt=media&token=6a627881-961b-4e2f-a55d-be76c687fc02',
        description: 'A session is a tool that enables you to create a customized plan of interventions '
        + 'to help you achieve your desired outcomes. You can specify the details of the session and set its status. '
        + '<br><br>Additionally, you can add comments and attachments to provide more context. '
        + 'By filling out the session form, you can create a tailored plan of action to support you in reaching your goals.'
    },
    {
        title: 'Intervention',
        imgPath: 'https://firebasestorage.googleapis.com/v0/b/kent-gentilezo.appspot.com/'
        + 'o/Learner_Hub%2Fintervention_form.gif?alt=media&token=360d2d75-b915-43fa-ae3a-365c24a20c8d',
        description: 'Interventions are specific strategies or techniques used '
        + 'to address a particular issue or achieve a desired outcome within a session. '
        + '<br><br>Filling up interventions involves setting a specific goal '
        + 'with a target date for achievement, and may also involve adding comments and attachments '
        + 'to provide additional context and support. It\'s an important step in implementing an intervention plan '
        + 'and helps keep the intervention on track.'
    },
    {
        title: 'The Board',
        imgPath: 'https://firebasestorage.googleapis.com/v0/b/kent-gentilezo.appspot.com/'
        + 'o/Learner_Hub%2Fboard_movement.gif?alt=media&token=fe5c2eac-0fd3-4679-980a-cf8ef516f514',
        description: 'The board is designed like a traditional kanban board, '
        + 'with columns representing the different statuses that sessions can have. '
        + 'It\'s user-friendly, allowing users to easily move session cards between columns to update their status. '
        + '<br><br>Importantly, the board is designed such that intervention updates '
        + 'automatically change session status, making it simple to monitor the progress of sessions in real time.'
    },
    {
        title: 'My Summary',
        imgPath: 'https://firebasestorage.googleapis.com/v0/b/kent-gentilezo.appspot.com/'
        + 'o/Learner_Hub%2Fmy_summary.gif?alt=media&token=19d719a9-4b53-4b9d-a94a-45ffd1a422ed',
        description: 'My Summary is designed to help users to quickly assess '
        + 'the current status and progress of all sessions and interventions within the system. '
        + 'It provides a high-level overview of the number of sessions and interventions '
        + 'that are open, closed, or hidden, '
        + 'as well as any deadlines that have passed or are upcoming. '
        + '<br><br>This feature enables users to easily track and manage the overall status of their work '
        + 'and make informed decisions about their next steps.'
    },
    {
        title: 'Admin Panel',
        imgPath: 'https://firebasestorage.googleapis.com/v0/b/kent-gentilezo.appspot.com/'
        + 'o/Learner_Hub%2Fadmin_panel.gif?alt=media&token=21bb394c-c12c-4964-b1cc-3e2f63e574d0',
        description: 'The admin panel in the app offers full CRUD functionality, enabling the user '
        + 'to manage options from the session\'s form and the intervention\'s form. '
        + 'The admins can disable, rename, reorder, and '
        + 'show/hide options if needed, giving complete control over the app\'s functionality. '
        + '<br><br>This feature provides customization options for an optimal user experience. '
        + 'With the admin panel, the admins can efficiently manage and modify the app\'s settings to suit specific needs.'
    },
    {
        title: 'Notifications',
        imgPath: 'https://firebasestorage.googleapis.com/v0/b/kent-gentilezo.appspot.com/'
        + 'o/Learner_Hub%2Fnotifications.gif?alt=media&token=4b902963-8267-422b-9fc9-09f58de25487',
        description: 'Users deleting sessions, interventions, '
        + 'or comments will create a pending notification '
        + 'for admins before the content is permanently deleted. '
        + '<br><br>The pending content is only accessible to admins '
        + 'through the notifications tab, giving them a chance to review and potentially intervene. '
        + 'This helps maintain the quality and integrity of the platform.'
    }
];
