import { Injectable } from '@angular/core';
import { take } from 'rxjs/operators';
import { Login, QueryResponse, User, UserDetail } from '../models';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import { ObjectUtils, OtherUtils, TranscodeUtils } from '../utils';
import { UserFirestoreService } from './firestore';
import { Router } from '@angular/router';
import { LocalStorageService } from './local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(
    private auth: AngularFireAuth,
    private localStorageService: LocalStorageService,
    private userFirestoreService: UserFirestoreService,
    private firestore: AngularFirestore,
    private router: Router
  ) {}

  getUser(): UserDetail {
    const userDetail: UserDetail = this.localStorageService.getUserFromToken();
    return userDetail;
  }

  login(credentials: Login): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.userFirestoreService.getUserByLogin(credentials.username, TranscodeUtils.encodeToBase64(credentials.password))
        .pipe(take(1))
        .subscribe((response: QueryResponse<User>) => {
          const userFound = !response.isEmpty;

          if (userFound) {
            const userData: User = response.data;
            const userId: string = response.id;

            this.auth.signInWithEmailAndPassword(userData.email, credentials.password).then(userCredential => {
              userCredential.user.getIdTokenResult().then(idtokenResult => {
                const userDetail: UserDetail = {
                  id: userId,
                  name: userData.name,
                  username: userData.username,
                  role: userData.role,
                  expirationTime: new Date(idtokenResult.expirationTime)
                };

                this.localStorageService.setUserToken(userDetail);
                resolve();
              }).catch(() => {
                reject();
                OtherUtils.hardClearConsole();
              });
            }).catch(() => {
              reject();
              OtherUtils.hardClearConsole();
            });
          } else {
            reject();
          }
      });
    });
  }

  logout(): void {
    this.auth.signOut();
    localStorage.clear();
    this.router.navigate(['/']);
  }

  isAuthenticated(): boolean {
    const currentlyLoggedIn: boolean = ObjectUtils.hasData(this.auth.currentUser);
    return this.isUserTokenValid() && currentlyLoggedIn;
  }

  isUserTokenValid(): boolean {
    const userDetail: UserDetail = this.localStorageService.getUserFromToken();
    const noUserToken: boolean = ObjectUtils.isEmpty(userDetail);

    let validToken = true;
    if (noUserToken) {
      validToken = false;
    } else {
      const timeNow = new Date();

      const isTokenExpired: boolean = timeNow > userDetail.expirationTime;
      if (isTokenExpired) {
        validToken = false;
      }
    }

    return validToken;
  }
}
