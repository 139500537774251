import { Injectable } from '@angular/core';
import { StorageData, UserDetail } from '../models';
import { ObjectUtils, TranscodeUtils } from '../utils';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {
  constructor() { }


  setUserToken(userDetail: UserDetail): void {
    const token: string = TranscodeUtils.encrypt<UserDetail>(userDetail);
    localStorage.setItem('token', token);
  }

  getUserToken(): string {
    return localStorage.getItem('token');
  }

  getUserFromToken(): UserDetail {
    const token: string = localStorage.getItem('token');
    const result: UserDetail = ObjectUtils.hasData(token) ? TranscodeUtils.decrypt<UserDetail>(token) : null;

    return result;
  }

  setDarkMode(darkMode: boolean): void {
    const expirationDate = new Date();
    expirationDate.setDate(expirationDate.getDate() + 2); // 2 days

    const storageData: StorageData<boolean> = {
      data: darkMode,
      expiration: expirationDate
    };

    const jsonData: string = JSON.stringify(storageData);
    localStorage.setItem('darkMode', jsonData);
  }

  getDarkMode(): boolean {
    const jsonData = localStorage.getItem('darkMode');

    if (ObjectUtils.isEmpty(jsonData)) {
      return false;
    }

    const storageData: StorageData<boolean> = JSON.parse(jsonData);

    const dateNow = new Date();
    if (dateNow  > storageData.expiration) {
      localStorage.removeItem('darkMode');
    }

    const result: boolean = storageData.data;
    return result;
  }
}
