import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { HomeRoutingModule } from './home-routing.module';
import { HomeComponent } from './home.component';
import { NavsModule } from '../navs/navs.module';
import { SharedModule } from '../_shared';
import { MainModule } from '../main/main.module';
import { PageFooterComponent } from '../page-footer/page-footer.component';


@NgModule({
  declarations: [HomeComponent, PageFooterComponent],
  imports: [
    CommonModule,
    MainModule,
    HomeRoutingModule,
    NavsModule,
    SharedModule
  ]
})
export class HomeModule { }
