import { Component, Input, OnInit  } from '@angular/core';
import { fadeNoLeaveAnimation } from '../_shared/animations';
import { NavEventsService } from '../_shared/services';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
  animations: [fadeNoLeaveAnimation]
})
export class MainComponent implements OnInit {
  @Input() darkMode: boolean = false;

  constructor(private navEventsService: NavEventsService) {
  }

  ngOnInit(): void {
  }

  clickSection(): void {
    if (this.checkMobileNavActive()) {
      this.navEventsService.toggleSideNav();
    }
  }

  private checkMobileNavActive(): boolean {
    return document.body.classList.contains('mobile-nav-active');
  }
}
