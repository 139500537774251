<!-- ======= Skills Section ======= -->
<section id="skills" class="skills" #skills>
    <div class="container" data-aos="fade-up">

        <div class="section-title">
            <h2>Skills</h2>
        </div>

        <div class="row">

            <div class="col-lg-4">
                <h3 class="skill-type-title text-center">Frontend</h3>
                <div class="skill-type-group">
                    <i class="bx bxl-html5 skill-icon"></i><h5 class="skill-type-icon">HTML</h5>
                </div>
                <div class="skill-type-group">
                    <i class="bx bxl-css3 skill-icon"></i><h5 class="skill-type-icon">CSS</h5>
                </div>
                <div class="skill-type-group">
                    <i class="bx bxl-typescript skill-icon"></i><h5 class="skill-type-icon">Typescript</h5>
                </div>
                <div class="skill-type-group">
                    <i class="bx bxl-angular skill-icon"></i><h5 class="skill-type-icon">Angular</h5>
                </div>
                <div class="skill-type-group">
                    <i class="bx bxl-javascript skill-icon"></i><h5 class="skill-type-icon">Javascript</h5>
                </div>
                <div class="skill-type-group">
                    <i class="bx bxl-bootstrap skill-icon"></i><h5 class="skill-type-icon">Bootstrap</h5>
                </div>
            </div>

            <div class="col-lg-4">
                <h3 class="skill-type-title text-center">Backend</h3>
                <div class="skill-type-group">
                    <img class="skill-icon" src="./assets/icons/icon-aspnetcore.svg" alt="asp.net core icon"><h5>Asp.Net Core</h5>
                </div>
                <div class="skill-type-group">
                    <img class="skill-icon" src="./assets/icons/icon-csharp.svg" alt="c sharp icon"><h5>C#</h5>
                </div>
                <div class="skill-type-group">
                    <i class="bx bxs-data skill-icon"></i><h5>T-SQL</h5>
                </div>
                <div class="skill-type-group">
                    <i class="bx bx-data skill-icon"></i><h5>NoSQL</h5>
                </div>
                <div class="skill-type-group">
                    <img class="skill-icon" src="./assets/icons/icon-restapi.svg" alt="rest api icon"><h5>RestfulAPI</h5>
                </div>
            </div>

            <div class="col-lg-4">
                <h3 class="skill-type-title text-center">Tools</h3>
                <div class="skill-type-group">
                    <img class="skill-icon" src="./assets/icons/icon-azure.svg" alt="azure icon"><h5>Azure</h5>
                </div>
                <div class="skill-type-group">
                    <i class="bx bxl-git skill-icon"></i><h5>Git</h5>
                </div>
                <div class="skill-type-group">
                    <i class="bx bxl-firebase skill-icon"></i><h5>Firebase</h5>
                </div>
                <div class="skill-type-group">
                    <img class="skill-icon" src="./assets/icons/icon-postman.svg" alt="postman icon"><h5>Postman</h5>
                </div>
                <div class="skill-type-group">
                    <img class="skill-icon" src="./assets/icons/icon-npm.svg" alt="npm icon"><h5>NPM</h5>
                </div>
            </div>
        </div>
        <div class="row">
            <h3 class="skill-type-title text-center">Working knowledge of</h3>
    
            <div class="col">
                <div class="skill-type-group">
                    <img class="skill-icon" src="./assets/icons/icon-visualbasic.svg" alt="visual basic icon"><h5>Visual Basic</h5>
                </div>
                <div class="skill-type-group">
                    <img class="skill-icon" src="./assets/icons/icon-clanguage.svg" alt="c language icon"><h5>C</h5>
                </div>
            </div>
            <div class="col">
                <div class="skill-type-group">
                    <img class="skill-icon" src="./assets/icons/icon-python.svg" alt="python icon"><h5>Python</h5>
                </div>
                <div class="skill-type-group">
                    <img class="skill-icon" src="./assets/icons/icon-php.svg" alt="php icon"><h5>PHP</h5>
                </div>
            </div>
            <div class="col">
                <div class="skill-type-group">
                    <img class="skill-icon" src="./assets/icons/icon-java.svg" alt="java icon"><h5>Java</h5>
                </div>
                <div class="skill-type-group">
                    <i class="bx bxl-unity skill-icon"></i><h5>Unity</h5>
                </div>
            </div>
        </div>

    </div>
</section><!-- End Skills Section -->