<!-- ======= Projects Section ======= -->
<section id="projects" class="projects" #projects>
    <div class="container" data-aos="fade-up">

        <div class="section-title">
            <h2>Projects</h2>
            <p>In this section, I've compiled some of my best projects to show you what I'm capable of as a software
                developer.</p>
        </div>
        <div class="row mb-8">
            <div class="col-lg-6 app-group">
                <button class="btn-restart" (click)="replayLearnerHubIntro()"><i class='bx bx-reset'></i> Restart
                    Image</button>
                <img src="{{ learnerHubImgSrc }}" class="img-fluid" alt="">
            </div>
            <div class="col-lg-6 info-group">
                <div class="title-section">
                    <h4><strong>Learner Hub</strong></h4>
                    <button class="btn btn-primary" (click)="openLearnerHubProject()"> Learn More</button>
                </div>
                <p>
                    Learner Hub is a project management tool that is similar to Kanban in its approach. It is utilized
                    by various organizations, such as universities, schools,
                    and other educational institutions, to create personalized learning experiences for their students.

                </p>
                <p>
                    With Learner Hub, educators can easily create sessions and interventions tailored to meet the unique
                    needs of each student, allowing for more efficient and effective learning. The platform also enables
                    educators to track students'
                    progress and provide feedback.
                </p>
                <h5><strong>Technologies</strong></h5>
                <div class="badge-group">
                    <span class="badge badge-learnerhub">Angular</span>
                    <span class="badge badge-learnerhub">Typescript</span>
                    <span class="badge badge-learnerhub">Asp.Net Core</span>
                    <span class="badge badge-learnerhub">IdentityServer4</span>
                    <span class="badge badge-learnerhub">C#</span>
                    <span class="badge badge-learnerhub">T-SQL</span>
                </div>
            </div>
        </div>

        <div class="row mb-8">
            <div class="col-lg-6 app-group">
                <button class="btn-restart" (click)="replayFundEaseIntro()"><i class='bx bx-reset'></i> Restart
                    Image</button>
                <img src="{{ fundeaseImgSrc }}" class="img-fluid" alt="">
            </div>
            <div class="col-lg-6 info-group">
                <div class="title-section">
                    <h4><strong>FundEase</strong></h4>
                    <button class="btn btn-primary" (click)="openFundEaseProject()"> Learn More</button>
                </div>
                <p>
                    FundEase is like a user-friendly spreadsheet tool that helps you manage your funding for different
                    projects or initiatives.
                    It provides an intuitive interface that allows you to easily distribute your funding,
                    without the need for complicated spreadsheets or manual calculations.
                </p>
                <p>
                    Overall, FundEase is a valuable financial management tool
                    that can is used by organizations or institutions that requires streamlined and efficient management
                    of their funding.
                </p>
                <h5><strong>Technologies</strong></h5>
                <div class="badge-group">
                    <span class="badge badge-learnerhub">Angular</span>
                    <span class="badge badge-learnerhub">Typescript</span>
                    <span class="badge badge-learnerhub">Asp.Net Core</span>
                    <span class="badge badge-learnerhub">IdentityServer4</span>
                    <span class="badge badge-learnerhub">C#</span>
                    <span class="badge badge-learnerhub">T-SQL</span>
                </div>
            </div>
        </div>

        <!-- Plain Text form-->
        <!-- <div class="row mb-8 project-container">
            <div class="title-section">
                <h4 style="color: #0563bb !important"><strong>Learner Hub</strong></h4>
            </div>
            <p>
                Learner Hub is a project management tool that is similar to Kanban in its approach. It is utilized
                by various organizations, such as universities, schools,
                and other educational institutions, to create personalized learning experiences for their students.

            </p>
            <p>
                With Learner Hub, educators can easily create sessions and interventions tailored to meet the unique
                needs of each student, allowing for more efficient and effective learning. The platform also enables
                educators to track students'
                progress and provide feedback.
            </p>
            <h5><strong>Technologies</strong></h5>
            <div class="badge-group">
                <span class="badge badge-learnerhub">Angular</span>
                <span class="badge badge-learnerhub">Typescript</span>
                <span class="badge badge-learnerhub">Asp.Net Core</span>
                <span class="badge badge-learnerhub">IdentityServer4</span>
                <span class="badge badge-learnerhub">C#</span>
                <span class="badge badge-learnerhub">T-SQL</span>
            </div>
        </div>
        <div class="row mb-8 project-container">
            <div class="title-section">
                <h4 style="color: #0563bb !important"><strong>FundEase</strong></h4>
            </div>
            <p>
                FundEase is like a user-friendly spreadsheet tool that helps you manage your funding for different
                projects or initiatives.
                It provides an intuitive interface that allows you to easily distribute your funding,
                without the need for complicated spreadsheets or manual calculations.
            </p>
            <p>
                Overall, FundEase is a valuable financial management tool
                that can is used by organizations or institutions that requires streamlined and efficient management of
                their funding.
            </p>
            <h5><strong>Technologies</strong></h5>
            <div class="badge-group">
                <span class="badge badge-learnerhub">Angular</span>
                <span class="badge badge-learnerhub">Typescript</span>
                <span class="badge badge-learnerhub">Asp.Net Core</span>
                <span class="badge badge-learnerhub">IdentityServer4</span>
                <span class="badge badge-learnerhub">C#</span>
                <span class="badge badge-learnerhub">T-SQL</span>
            </div>
        </div> -->
    </div>
</section><!-- End Projects Section -->