import { AfterViewInit, Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { SideNavOffset } from '../_shared/models';
import { ObjectUtils } from '../_shared/utils';
import { ScrollService } from '../_shared/services';
import { SideNavTabEnum } from '../_shared/enums';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit, AfterViewInit {
  @ViewChild('profile') profileSection: ElementRef<HTMLElement>;

  constructor(private scrollService: ScrollService) { }

  @HostListener('window:resize', ['$event'])
  onWindowResize(): void {
    this.sendOffsetData();
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.sendOffsetData();
  }

  goToProjects(): void{
    this.scrollService.scrollToTab(SideNavTabEnum.projects);
  }

  private sendOffsetData(): void {
    if (ObjectUtils.isEmpty(this.profileSection)) {
      return;
    }

    const offsetTop: number = this.profileSection.nativeElement.offsetTop;
    const offsetHeight: number = this.profileSection.nativeElement.offsetHeight;
    const offset: SideNavOffset = {
      top: offsetTop,
      height: offsetHeight
    };

    this.scrollService.setOffset(SideNavTabEnum.home, offset);
  }
}
