import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Notification } from 'src/app/_shared/models';
import { MockDataHelper } from '.';
import { INotificationService } from '../../interfaces';

@Injectable({
  providedIn: 'root'
})
export class MockNotificationService implements INotificationService {

  constructor() { }

  getNotifications(): Observable<Notification[]> {
    const notifications: Notification[] = [];
    for (let i = 0; i < 10; i++) {
      notifications.push(MockDataHelper.createNotification());
    }

    return of(notifications);
  }
}
