export interface Environment {
  production: boolean;
  firebase: {
    apiKey: string;
    authDomain: string;
    databaseURL: string;
    projectId: string;
    storageBucket: string;
    messagingSenderId: string;
    appId: string;
    measurementId: string;
  };
  secretKey: string;
  enablePayments: boolean;
  stripePublishableKey: string;
  apiEndpoint: string;
  resumePath: string;
}
