import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NotFoundComponent } from './_shared/components';
import { HomeComponent } from './home/home.component';
import { ResumeFileComponent } from './resume-file/resume-file.component';
import { AppGuard } from './_shared/guards';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'home'
  },
  { path: 'home', component: HomeComponent, canActivate: [AppGuard] },
  { path: 'about', component: HomeComponent, canActivate: [AppGuard] },
  { path: 'skills', component: HomeComponent, canActivate: [AppGuard] },
  { path: 'projects', component: HomeComponent, canActivate: [AppGuard] },
  { path: 'resume', component: HomeComponent, canActivate: [AppGuard] },
  { path: 'resume/view', component: ResumeFileComponent, canActivate: [AppGuard] },
  { path: 'contact', component: HomeComponent, canActivate: [AppGuard] },
  { path: '404', component: NotFoundComponent, canActivate: [AppGuard] },
  { path: '**', redirectTo: '404' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
