<!-- ======= Contact Section ======= -->
<section id="contact" class="contact" #contact>
  <div class="container" data-aos="fade-up">

    <div class="section-title">
      <h2>Contact Me</h2>
    </div>

    <div class="row">
      <div class="col card-group mb-4" data-aos="zoom-in" data-aos-delay="100" (click)="openEmail()">
        <div class="icon-box">
          <div class="icon contact-icon">
            <div class="bg-icon">
              <i class="bx bxs-envelope"></i>
            </div>
            <h5>Email</h5>
          </div>
          <p class="mt-2">Let's get in touch.</p>
        </div>
      </div>

      <div class="col card-group mb-4" data-aos="zoom-in" data-aos-delay="200" (click)="openLinkedIn()">
        <div class="icon-box">
          <div class="icon contact-icon">
            <div class="bg-icon">
              <i class="bx bxl-linkedin-square"></i>
            </div>
            <h5>LinkedIn</h5>
          </div>
          <p class="mt-2">Let's connect.</p>
        </div>
      </div>
    </div>

  </div>
</section><!-- End Contact Section -->