  <!-- ======= Footer ======= -->
  <footer id="footer" (click)="clickSection()">
    <div class="container">
      <div class="copyright">
        &copy; Copyright <strong><span>KentGentilezo</span></strong>. All Rights Reserved
      </div>
      <!-- 
      <div class="credits">
      </div>
      -->
       <!-- Designed by BootstrapMade: "https://bootstrapmade.com/"-->
    </div>
  </footer><!-- End Footer -->