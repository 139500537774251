/* eslint-disable no-console */
export class OtherUtils {
    static hardClearConsole(): void {
        setTimeout(() => {
            console.clear();
        }, 0);
    }

    static resetImgSrc(imgSrc: string): string {
        // Applied cache busting query to create a new instance of the image
        return `${imgSrc}&${new Date().getTime()}`;
    }
}
