import { NgModule } from '@angular/core';
import { FeatherModule } from 'angular-feather';
import {
  Camera, Check, Heart, Github, FileText, Sliders, List, ChevronDown, Download, MoreVertical,
  Settings, CheckCircle, ChevronLeft, ChevronRight, ChevronUp, XCircle, AlertCircle, Maximize2, ChevronsRight, ChevronsLeft, X,
  Plus, Minus, Menu, Inbox, LogOut, Clock, Slash, Edit2, RefreshCw
} from 'angular-feather/icons';

/* eslint-disable @typescript-eslint/naming-convention */
// Select some icons (use an object, not an array)
const icons = {
  Camera,
  Check,
  Heart,
  Github,
  FileText,
  Sliders,
  List,
  MoreVertical,
  Settings,
  Download,
  ChevronDown,
  ChevronRight,
  ChevronLeft,
  ChevronUp,
  CheckCircle,
  XCircle,
  AlertCircle,
  Maximize2,
  ChevronsLeft,
  ChevronsRight,
  X,
  Plus,
  Minus,
  Menu,
  Inbox,
  LogOut,
  Clock,
  Slash,
  Edit2,
  RefreshCw
};
@NgModule({
  imports: [
    FeatherModule.pick(icons)
  ],
  exports: [
    FeatherModule
  ]
})
export class IconsModule { }

// NOTES:
// 1. We add FeatherModule to the 'exports', since the <i-feather> component will be used in templates of parent module
// 2. Don't forget to pick some icons using FeatherModule.pick({ ... })
