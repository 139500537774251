import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncate'
})
export class TruncatePipe implements PipeTransform {

  transform(value: string, max = 30, ellipsis = '...'): string {
    return value.length > max ? value.substr(0, max) + ellipsis : value;
  }
}
