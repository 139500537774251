<!-- ======= Profile Section ======= -->
<section id="profile" class="d-flex flex-column justify-content-center" #profile>
  <div class="container" data-aos="zoom-in" data-aos-delay="100">
    <h2>Hey there, I'm</h2>
    <h1>Kent Gentilezo</h1>
    <ngx-typed-js [strings]="['Software Engineer', 'Full Stack Developer', 'Frontend Developer', 'Backend Developer']"
      [loop]="true" [typeSpeed]="100" [backSpeed]="50" [backDelay]="2000">
      <p>I'm a <span class="typing"></span></p>
    </ngx-typed-js>
    <div class="col button-group">
      <a class="btn btn-profile" (click)="goToProjects()">View Projects</a>
    </div>
  </div>
</section><!-- End Profile -->