<div class="modal-header">
    <span class="modal-title" [ngStyle]="{'color': colorTheme}">{{ projectScreens[index].title }}</span>
    <div class="modal-header__right"><i class='bx bx-x btn-modal__close' (click)="close()" [ngStyle]="{ 'color': colorTheme }"></i></div>
</div>
<div class="modal-body">
    <div class="screen-group">
        <button class="btn-restart" (click)="replayImage()" *ngIf="!imageLoading"><i class='bx bx-reset'></i> Restart Image</button>
        <div class="mb-3 app-screen">
            <div id="preloader" *ngIf="imageLoading"></div>
            <img src="{{ imgSrc }}" class="project-img" alt="" (load)="imageLoaded()" height="305" width="540">
        </div>    
    </div>
    <div class="details-group">
        <p class="description" [innerHtml]="projectScreens[index].description "></p>
    </div>
</div>
<div class="modal-footer">
    <app-custom-pagination [itemLength]="projectScreens?.length" [colorTheme]="data?.colorTheme" 
        [fontColor]="data?.fontColor" (indexUpdated)="setCurrentIndex($event)">
    </app-custom-pagination>
</div>