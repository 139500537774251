<div class="main-container" (click)="clickSection()">
  <main id="main" #main>
    <app-profile></app-profile>
    <app-about></app-about>
    <app-skills></app-skills>
    <app-projects [darkMode]="darkMode"></app-projects>
    <app-resume></app-resume>
    <app-contact></app-contact>
  </main>
</div>
