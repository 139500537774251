<div class="confirmation-modal">
  <div class="modal-header">
    <h2 class="modal-title">{{data.title || 'Confirmation'}}</h2>
  </div>
  <div class="modal-body">
    <p *ngIf="data.message" [innerHtml]="data.message"></p>
  </div>
  <div class="modal-footer">
    <button (click)="modalInstance.close()" type="button" class="btn btn-muted">
      {{data.declineText || 'Decline'}}
    </button>

    <button (click)="modalInstance.close()" type="button" class="btn btn-primary">
      {{data.confirmText || 'Confirm'}}
    </button>
  </div>
</div>