<header class="top-nav">
  <nav class="top-nav__nav">
    <!-- LOGO & TITLE -->
    <div class="top-nav__left" (click)="goHome()">
      <div class="top-nav__left__logo">
        <img src="../../../assets/icons/icon-logo-white.svg" alt="LH logo">
      </div>

      <div class="top-nav__left__title">
        <span class="text-white">Portfolio Kent</span>
      </div>
    </div>

    <!-- USER -->
    <div class="top-nav__right d-flex">
      <div ngbDropdown *ngIf="user?.role" class="navbar__role">
        <p>{{user?.role}}</p>
      </div>

      <div ngbDropdown class="user-thumbnail-dropdown" id="dropdownMenu" ngbDropdownToggle display="ngbDropdownMenu"
        placement="bottom-right">
        <div class="user-thumbnail-dropdown__user-information">
          <h4 class="user-thumbnail-dropdown__user-information__profile-name">
            {{user?.name}}
          </h4>
        </div>

        <div ngbDropdownMenu aria-labelledby="dropdownMenu" class="dropdown-menu-item">
            <button ngbDropdownItem type="button" (click)="logout()">
              <i-feather name="log-out"></i-feather>
              <span class="ml-2 mt-1"> Log out</span>
            </button>
        </div>
      </div>
    </div>
  </nav>
</header>
