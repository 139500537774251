import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-resume-file',
  templateUrl: './resume-file.component.html',
  styleUrls: ['./resume-file.component.scss']
})
export class ResumeFileComponent implements OnInit {
  fileUrl: string = environment.resumePath;

  constructor() { }

  ngOnInit(): void {
  }
}
