import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AngularFireAuthGuard } from '@angular/fire/auth-guard';
import { Observable, of } from 'rxjs';
import { AuthService } from '../services';
import { FIREBASE_APP_URL1, FIREBASE_APP_URL2, OFFICIAL_APP_URL } from '../constants';

@Injectable({
    providedIn: 'root'
})
export class AppGuard implements CanActivate {
    constructor(private fireAuthGuard: AngularFireAuthGuard, private authService: AuthService, private router: Router) { }

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.checkUser(next, state);
    }

    private checkUser(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        this.redirectCheck();
        return of(true);
    }

    private redirectCheck(): void {
        const currentUrl = window.location.href.toLowerCase();

        if (currentUrl.includes(FIREBASE_APP_URL1)){
            const matchingText: string = FIREBASE_APP_URL1;
            const replacedText: string = OFFICIAL_APP_URL;
            const redirectUrl: string = currentUrl.replace(matchingText, replacedText);
            window.location.href = redirectUrl;
        } else if (currentUrl.includes(FIREBASE_APP_URL2)){
            const matchingText: string = FIREBASE_APP_URL2;
            const replacedText: string = OFFICIAL_APP_URL;
            const redirectUrl: string = currentUrl.replace(matchingText, replacedText);
            window.location.href = redirectUrl;
        }
    }
}
