export interface AlertModalOptions {
  title?: string;
  buttonText?: string;
  message?: string;
}

export interface AlertModalMessage {
  type: string;
  message?: string;
}
