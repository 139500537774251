export interface AppConfig {
    env: {
      name: string;
    };
    apiEndpoint: string;
    identity: {
      endpoint: string;
      clientId: string;
      scope: string;
      responseType: string;
      callbackUrl: string;
      postLogoutRedirectUrl: string;
      adminSettingId: number;
      userSettingId: number;
    };
  }

