import { Component, ContentChildren, Input, OnInit, QueryList } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Subscription } from 'rxjs/internal/Subscription';
import { AlertMessageService } from 'src/app/_shared/services/alert-message.service';

import { ALERT_MESSAGE_TYPES } from '../../constants';
import { CustomHtmlMessageDirective } from '../../directives/alert-message.directive';
import { AlertMessage } from '../../models';

@UntilDestroy()
@Component({
  selector: 'app-alert-message',
  templateUrl: './alert-message.component.html',
  styleUrls: ['./alert-message.component.scss']
})
export class AlertMessageComponent implements OnInit {
  @Input() id = 'default-alert';
  @Input() fade = true;
  @Input() timeout = 3000;
  @ContentChildren(CustomHtmlMessageDirective) customHtmlMessageRef!: QueryList<CustomHtmlMessageDirective>;

  readonly alertMessageTypes: Record<number, string> = ALERT_MESSAGE_TYPES;

  messages: AlertMessage[] = [];
  messageSubscription!: Subscription;
  routeSubscription!: Subscription;

  constructor(
    private router: Router,
    private alertMessageService: AlertMessageService) { }

  ngOnInit(): void {
    this.messageSubscription = this.alertMessageService.onAlert(this.id)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (message: AlertMessage) => {
          if (!message.message) {
            this.messages = this.messages.filter(x => x.keepAfterRouteChange);
            this.messages.forEach(x => delete x.keepAfterRouteChange);
            return;
          }

          this.messages.push(message);

          if (message.autoClose) {
            setTimeout(() => this.removeAlert(message), this.timeout);
          }
        },
        error: () => {

        }
      });

    this.routeSubscription = this.router.events
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (event) => {
          if (event instanceof NavigationStart) {
            this.alertMessageService.clear(this.id);
          }
        },
        error: () => {

        }
      });
  }

  removeAlert(message: AlertMessage): void {
    if (!this.messages.find(m => m.id === message.id)) {
      return;
    }

    if (this.fade) {
      const fadeMessage: AlertMessage | undefined = this.messages.find(m => m === message);

      if (fadeMessage) {
        fadeMessage.fade = true;
      }

      setTimeout(() => {
        this.messages = this.messages.filter(m => m.id !== message.id);
      }, 250);
    } else {
      this.messages = this.messages.filter(m => m.id !== message.id);
    }
  }

  cssClass(message: AlertMessage): string {
    if (!message) {
      return '';
    }

    const classes: string[] = [];
    if (message.dismissible) {
      classes.push('alert-dismissible');
    }

    classes.push(ALERT_MESSAGE_TYPES[message.type]);

    if (message.fade) {
      classes.push('fade');
    }

    return classes.join(' ');
  }
}
