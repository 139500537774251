import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserDetail } from 'src/app/_shared/models';
import { AuthService, ComponentModalService, LocalStorageService } from 'src/app/_shared/services';

@Component({
  selector: 'app-top-nav',
  templateUrl: './top-nav.component.html',
  styleUrls: ['./top-nav.component.scss']
})
export class TopNavComponent implements OnInit {
  // True: lg => xl --- False: xs=>sm
  @Input() isNavbarShown!: boolean;
  // True: Collapsed or folded --- False: lg=>xl or when hamburger is clicked
  @Input() isNavbarCollapsed!: boolean;

  topNavHeaderText!: string;
  user!: UserDetail;
  loggedAsAdmin: boolean = false;

  constructor(
    public auth: AuthService,
    private router: Router,
    private modalService: ComponentModalService,
    private localStorageService: LocalStorageService
  ) { }

  ngOnInit(): void {
    this.user = this.auth.getUser();
    this.topNavHeaderText = this.router.url.slice(1)?.split('/')[1].replace('-', ' ');
    this.subscribeToEvents();
    this.setupRoleSubscription();
  }

  subscribeToEvents(): void {
    // // When the user is initialized by auth service
    // this.navEventsService.userInitialized.subscribe((user) => {
    //   if (user.givenName&& user.givenName !== '') {
    //     this.user = user;
    //   }
    // });

    // // Router event navigate
    // this.router.events.subscribe((event: Event) => {
    //   if (event instanceof NavigationEnd) {
    //     const urlDelimitators = new RegExp(/[?,//;&:#$+=]/);
    //     const fullRoute: string[] = event.url.slice(1)?.split(urlDelimitators);
    //     this.topNavHeaderText = fullRoute[1]?.replace(/-/g, ' ') ?? 'Home';
    //   }
    // });
  }

  setupRoleSubscription(): void {
    // this.auth.rolesChanged.subscribe(() => {
    //   this.loggedAsAdmin = this.auth.loggedAsAdmin;
    //   this.role = this.auth.getUser().roles.find((role: Role) =>
    //     AppConfigService.settings.allowedRoles.adminRoles.filter((permission) => permission.roleId === role.id).length > 0
    //     || AppConfigService.settings.allowedRoles.userRoles.filter((permission) => permission.roleId === role.id).length > 0);
    // });

    // this.loggedAsAdmin = this.auth.loggedAsAdmin;

    // if(this.auth.getUser().roles){
    //   this.role = this.auth.getUser().roles.find((role: Role) =>
    //   AppConfigService.settings.allowedRoles.adminRoles.filter((permission) => permission.roleId === role.id).length > 0
    //   || AppConfigService.settings.allowedRoles.userRoles.filter((permission) => permission.roleId === role.id).length > 0);
    // }
  }

  isTabActive(path: string): boolean {
    return this.router.url.includes(path);
  }

  navigate(route: string): void {
    this.router.navigate([`/app/${route}`]);
  }

  logout(): void {
    this.auth.logout();
  }

  goHome(): void {
    const path = `app/settings`;
    this.router.navigate([path], { replaceUrl: true }).then(() => window.location.reload());
  }
}
