import { Environment } from 'src/app/_shared/models';

export const environment: Environment = {
  production: false,
  firebase: {
    databaseURL: 'https://kent-gentilezo.firebaseio.com',
    apiKey: "AIzaSyCajwXYL6-Nj4ESKkenpbAtEU15dM8YVkA",
    authDomain: "kent-gentilezo.firebaseapp.com",
    projectId: "kent-gentilezo",
    storageBucket: "kent-gentilezo.appspot.com",
    messagingSenderId: "71486143344",
    appId: "1:71486143344:web:dd41775f367ed205e0edb5",
    measurementId: "G-WHJ7K823P4"
  },
  secretKey: 'k3ntgent1lez0',
  apiEndpoint: 'http://localhost:53494/',
  resumePath: '/assets/files/Kent Gentilezo - Software Engineer Resume.pdf',
  stripePublishableKey: '',
  enablePayments: true
};