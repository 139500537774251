<!-- ======= About Section ======= -->
<section id="about" class="about" #about>
    <div class="container" data-aos="fade-up">

        <div class="section-title">
            <h2>About</h2>
        </div>

        <div class="row">
            <div class="content">
                <p>
                    I'm a software engineer based in Cavite, Philippines and I specialize in full stack development. 
                    I started coding at a very young age, and what started as a hobby has now turned into a passion. 
                </p>
                <p>
                    My journey in programming began around 2010 when I started exploring Visual Basic and 
                    developed a keen interest in coding. Since then, I have been constantly learning and keeping up 
                    with the latest trends and technologies in the field.
                </p>
                <p>
                    Aside from coding, I also enjoy video editing and watching Marvel movies in my free time. 
                    I am also an avid basketball fan and enjoy playing the sport whenever I can.
                </p>
            </div>
        </div>

    </div>
</section><!-- End About Section -->