import { AfterViewInit, Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { SideNavOffset } from '../_shared/models';
import { ObjectUtils } from '../_shared/utils';
import { ScrollService } from '../_shared/services';
import { SideNavTabEnum } from '../_shared/enums';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit, AfterViewInit {
  @ViewChild('about') aboutSection: ElementRef<HTMLElement>;

  constructor(private scrollService: ScrollService) { }

  @HostListener('window:resize', ['$event'])
  onWindowResize(): void {
    this.sendOffsetData();
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.sendOffsetData();
  }

  private sendOffsetData(): void {
    if (ObjectUtils.isEmpty(this.aboutSection)) {
      return;
    }

    const offsetTop: number = this.aboutSection.nativeElement.offsetTop;
    const offsetHeight: number = this.aboutSection.nativeElement.offsetHeight;

    const offset: SideNavOffset = {
      top: offsetTop,
      height: offsetHeight
    };

    this.scrollService.setOffset(SideNavTabEnum.about, offset);
  }
}
