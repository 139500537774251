
    <i class="bi mobile-nav-toggle d-lg-none" [ngClass]="!menuToggled ? 'bi-list' : 'bi-x'" (click)="toggleMenu()"></i>

    <header id="header" class="d-flex flex-column justify-content-center">
    
        <nav id="navbar" class="navbar nav-menu" [ngClass]="{ 'dark': darkMode }">
            <ul>
                <li><a class="nav-link" [class.active]="isTabActive(tabs.home)" [ngClass]="{'active': isTabActive(tabs.home)}" (click)="navigateTab(tabs.home)"><i class="bx bx-home"></i> <span>Home</span></a></li>
                <li><a class="nav-link" [class.active]="isTabActive(tabs.about)" (click)="navigateTab(tabs.about)"><i class="bx bx-user"></i> <span>About</span></a></li>
                <li><a class="nav-link" [class.active]="isTabActive(tabs.skills)" (click)="navigateTab(tabs.skills)"><i class="bx bx-layer"></i> <span>Skills</span></a></li>
                <li><a class="nav-link" [class.active]="isTabActive(tabs.projects)" (click)="navigateTab(tabs.projects)"><i class="bx bx-book-content"></i><span>Projects</span></a></li>
                <li><a class="nav-link" [class.active]="isTabActive(tabs.resume)" (click)="navigateTab(tabs.resume)"><i class="bx bx-file-blank"></i> <span>Resume</span></a></li>
                <li><a class="nav-link" [class.active]="isTabActive(tabs.contact)" (click)="navigateTab(tabs.contact)"><i class="bx bx-envelope"></i> <span>Contact</span></a></li>
                <li class="mt-3 toggle-switch">
                    <a class="nav-link">
                        <i class="bx bxs-sun" *ngIf="!darkMode"></i>
                        <i class="bx bxs-moon" *ngIf="darkMode"></i>
                        <ui-switch class="toggle" [(ngModel)]="darkMode" (change)="toggleDarkMode($event)" size="large" color="{{ colorBlack }}" 
                            defaultBgColor="{{ navColor }}" id="summaryToggle" name="summaryToggle"
                            labelOn="<i class='bx bxs-sun mr-2'></i> Dark" labelOff="Light <i class='bx bxs-sun ml-1'></i>">
                        </ui-switch>
                    </a>
                </li>

            </ul>
        </nav><!-- .nav-menu -->
 
    </header><!-- End Header -->
