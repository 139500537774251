export interface User {
    name: string;
    username: string;
    email: string;
    role: string;
}

export interface UserDetail {
    id: string;
    name: string;
    username: string;
    role: string;
    expirationTime: Date;
}
