import { enc, AES } from 'crypto-js';
import { environment } from 'src/environments/environment';


export class TranscodeUtils {
    static encrypt<T>(data: T): string {
        const jsonData: string = JSON.stringify(data);
        return AES.encrypt(jsonData, environment.secretKey).toString();
    }

    static decrypt<T>(encrypted: string): T {
        const decryptedJson: string = AES.decrypt(encrypted, environment.secretKey).toString(enc.Utf8);

        return JSON.parse(decryptedJson) as T;
    }

    static encodeToBase64(text: string): string {
        return btoa(text);
    }

    static decodeFromBase64(text: string): string {
        return atob(text);
    }
  }
