import { Injectable } from '@angular/core';
import { SideNavTabEnum } from '../enums';
import { SideNavOffset } from '../models';

@Injectable({
  providedIn: 'root'
})
export class ScrollService {
  private offsets: Record<SideNavTabEnum, SideNavOffset> = {
    [SideNavTabEnum.home]: null,
    [SideNavTabEnum.about]: null,
    [SideNavTabEnum.skills]: null,
    [SideNavTabEnum.resume]: null,
    [SideNavTabEnum.projects]: null,
    [SideNavTabEnum.contact]: null
  };

  constructor() {}

  setOffset(tab: SideNavTabEnum, offset: SideNavOffset): void {
    this.offsets[tab] = offset;
  }

  scrollToTab(tab: string): void {
    window.scrollTo({top: this.isTabTopScrollable(tab) ? this.offsets[tab]?.top : this.offsets[tab]?.top - 300, behavior: 'smooth'});
  }

  getTabSectionOnScreen(): string {
    let tabOnScreen: string = null;

    Object.values(SideNavTabEnum).forEach(tab => {
      const position: number = this.isTabTopScrollable(tab) ? window.scrollY + 200 : window.scrollY + 800;
      const isTabOnScreen: boolean = position >= this.offsets[tab]?.top &&
        (position <= (this.offsets[tab]?.top + (this.offsets[tab]?.height)));
      if (isTabOnScreen) {
        tabOnScreen = tab;
      }
    });

    return tabOnScreen;
  }

  private isTabTopScrollable(tab: string): boolean {
    // Checking if Tab's Top Offset is Reachable by Scrolling
    return this.offsets[tab]?.top < this.getLastScrollY();
  }

  private getLastScrollY(): number {
    // Get the last scroll Y position of the whole page
    return document.body.scrollHeight - window.innerHeight;
  }
}
