import { AfterViewInit, Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { SideNavOffset } from '../_shared/models';
import { ObjectUtils } from '../_shared/utils';
import { ScrollService } from '../_shared/services';
import { SideNavTabEnum } from '../_shared/enums';

@Component({
  selector: 'app-skills',
  templateUrl: './skills.component.html',
  styleUrls: ['./skills.component.scss']
})
export class SkillsComponent implements OnInit, AfterViewInit {
  @ViewChild('skills') skillsSection: ElementRef<HTMLElement>;

  constructor(private scrollService: ScrollService) { }

  @HostListener('window:resize', ['$event'])
  onWindowResize(): void {
    this.sendOffsetData();
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.sendOffsetData();
  }

  private sendOffsetData(): void {
    if (ObjectUtils.isEmpty(this.skillsSection)) {
      return;
    }

    const offsetTop: number = this.skillsSection.nativeElement.offsetTop;
    const offsetHeight: number = this.skillsSection.nativeElement.offsetHeight;
    const offset: SideNavOffset = {
      top: offsetTop,
      height: offsetHeight
    };

    this.scrollService.setOffset(SideNavTabEnum.skills, offset);
  }
}
