import { Component, ElementRef, HostListener, OnInit, ViewChild, AfterViewInit, Input } from '@angular/core';
import { ProjectDisplayOptions, SideNavOffset } from '../_shared/models';
import { ObjectUtils, OtherUtils } from '../_shared/utils';
import { ComponentModalService, ScrollService } from '../_shared/services';
import { SideNavTabEnum } from '../_shared/enums';
import { COLOR_WHITE, FUNDEASE_SCREENS, FUNDEASE_THEME,
  FUNDEASE_THEME_LIGHT, LEARNER_HUB_SCREENS,
  LEARNER_HUB_THEME, LEARNER_HUB_THEME_LIGHT
} from '../_shared/constants';

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss']
})
export class ProjectsComponent implements OnInit, AfterViewInit {
  @ViewChild('projects') projectsSection: ElementRef<HTMLElement>;
  @Input() darkMode: boolean = false;

  readonly learnerHubImgPath: string = 'https://firebasestorage.googleapis.com/v0/b/kent-gentilezo.appspot.com/'
  + 'o/Learner_Hub%2Flearner_hub_intro.gif?alt=media&token=5f951e0a-9d17-43de-941e-ce8f3d9b63c8';
  readonly fundeaseImgPath: string = 'https://firebasestorage.googleapis.com/v0/b/kent-gentilezo.appspot.com/'
  + 'o/FundEase%2Ffundease_intro.gif?alt=media&token=a2dd4c77-846f-41a0-ac5f-1103b11d07e6';

  learnerHubImgSrc: string;
  fundeaseImgSrc: string;

  constructor(
    private modalService: ComponentModalService,
    private scrollService: ScrollService
    ) { }

  @HostListener('window:resize', ['$event'])
  onWindowResize(): void {
    this.sendOffsetData();
  }

  ngOnInit(): void {
    this.initializeData();
  }

  ngAfterViewInit(): void {
    this.sendOffsetData();
  }

  openLearnerHubProject(): void {
    const options: ProjectDisplayOptions = {
      projectName: 'Learner Hub',
      projectScreens: LEARNER_HUB_SCREENS,
      colorTheme: this.darkMode ? LEARNER_HUB_THEME_LIGHT : LEARNER_HUB_THEME,
      fontColor: COLOR_WHITE
    };
    this.openProject(options);
  }

  replayLearnerHubIntro(): void {
    this.learnerHubImgSrc = OtherUtils.resetImgSrc(this.learnerHubImgPath);
  }

  replayFundEaseIntro(): void {
    this.fundeaseImgSrc = OtherUtils.resetImgSrc(this.fundeaseImgPath);
  }

  openFundEaseProject(): void {
    const options: ProjectDisplayOptions = {
      projectName: 'FundEase',
      projectScreens: FUNDEASE_SCREENS,
      colorTheme: this.darkMode ? FUNDEASE_THEME_LIGHT : FUNDEASE_THEME,
      fontColor: COLOR_WHITE
    };
    this.openProject(options);
  }

  private openProject(options: ProjectDisplayOptions): void {
    const noScrollClass = 'modal-opened';
    document.body.classList.add(noScrollClass);

    this.modalService.showProjectDisplayModal(options)
    .then(() => {})
    .finally(() => {
      document.body.classList.remove(noScrollClass);
    }).catch(() => { });
  }

  private sendOffsetData(): void {
    if (ObjectUtils.isEmpty(this.projectsSection)) {
      return;
    }

    const offsetTop: number = this.projectsSection.nativeElement.offsetTop;
    const offsetHeight: number = this.projectsSection.nativeElement.offsetHeight;
    const offset: SideNavOffset = {
      top: offsetTop,
      height: offsetHeight
    };

    this.scrollService.setOffset(SideNavTabEnum.projects, offset);
  }

  private initializeData(): void {
    this.learnerHubImgSrc = this.learnerHubImgPath;
    this.fundeaseImgSrc = this.fundeaseImgPath;
  }
}
