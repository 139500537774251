import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { QueryResponse, SearchParam, User } from 'src/app/_shared/models';
import { BaseFirestoreService } from './base-firestore.service';

@Injectable({
  providedIn: 'root'
})
export class UserFirestoreService {

  constructor(
    private baseFirestoreService: BaseFirestoreService
  ) { }

  getUserByLogin(username: string, hashedPassword: string): Observable<QueryResponse<User>> {
    const searchParams: SearchParam[] = [
      {column: 'username', value: username},
      {column: 'password', value: hashedPassword}
    ];
    const result: Observable<QueryResponse<User>> = this.baseFirestoreService.getDataByColumns<User>('users', searchParams);
    return result;
  }
}
