/* eslint-disable @typescript-eslint/no-explicit-any */
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbAlertModule, NgbDropdownModule, NgbModalModule, NgbPaginationModule,
  NgbProgressbarModule, NgbTooltipModule, NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { FileUploadModule } from '@iplab/ngx-file-upload';

import { AlertMessageComponent } from './components/messages';
import { ConfirmationModalComponent, CustomPaginationComponent, ProjectDisplayComponent } from './components/modals';
import { ButtonSpinnerDirective, CustomHtmlMessageDirective, GrowAnimationDirective } from './directives';
import { FilterPipe, PaginatePipe, TruncatePipe } from './pipes';

import { NgSelectModule } from '@ng-select/ng-select';
import { EmptyStateComponent, LoaderComponent } from './components';
import { IconsModule } from './icons/icons.module';
import { NgxTypedJsModule } from 'ngx-typed-js';
import { NgxDocViewerModule } from 'ngx-doc-viewer';
import { UiSwitchModule } from 'ngx-toggle-switch';

export const components: any[] = [
  AlertMessageComponent,
  ConfirmationModalComponent,
  EmptyStateComponent,
  LoaderComponent,
  ProjectDisplayComponent,
  CustomPaginationComponent
];

export const pipes: any[] = [
  FilterPipe,
  TruncatePipe,
  PaginatePipe
];

export const directives: any[] = [
  CustomHtmlMessageDirective,
  GrowAnimationDirective,
  ButtonSpinnerDirective
];

export const importModules: any[] = [
  CommonModule,
  FormsModule,
  RouterModule
];

export const exportModules: any[] = [
  CommonModule,
  FormsModule,
  RouterModule
];

export const thirdPartyModules: any[] = [
  FileUploadModule,
  IconsModule,
  NgbAlertModule,
  NgbDropdownModule,
  NgbModalModule,
  NgbProgressbarModule,
  NgbPopoverModule,
  NgbPaginationModule,
  NgSelectModule,
  NgbTooltipModule,
  NgxTypedJsModule,
  NgxDocViewerModule,
  UiSwitchModule
];

@NgModule({
  declarations: [
    ...components,
    ...directives,
    ...pipes
  ],
  imports: [
    ...importModules,
    ...thirdPartyModules
  ],
  providers: [],
  bootstrap: [],
  exports: [
    ...exportModules,
    ...components,
    ...directives,
    ...thirdPartyModules,
    ...pipes
  ]
})
export class SharedModule { }
